<template>
  <div class="case-container">
    <div v-show="Boolean(loading)" v-loading="true" element-loading-background="rgba($color: #fff, $alpha: .9))"
      class="case-loading" />
    <el-scrollbar class="breadcrumb-wp">
      <span @click="onBreadcrumb(i)" v-for="(v, i) in breadcrumbList" :key="v.id" :class="{ pointer: v.id }"
        class="breadcrumb-item">{{ [96, 97, 98].includes(v.typeId) ? v.fullName : v.name }}</span>
    </el-scrollbar>

    <div class="firstParent flex" v-if="firstParent && firstParent.length">
      <div class="item" @click="changeFirstParent(item)" :class="{ cur: item.id === firstParentInfo.id }"
        v-for="(item, index) in firstParent" :key="index">
        {{ item.name }}
      </div>
    </div>

    <div v-if="labelList" class="case-main" :class="{ radius: firstParent.length }">
      <div class="case-main-main">
        <div :class="{ 'w-0': !isShowAside }" v-if="!firstParent.length" class="case-aside">
          <div class="case-show" v-if="isShowAside">
            <div class="case-show-left flex jc_b">
              <span v-if="caseIds.includes(breadcrumb.typeId)">案件列表({{ parentList.length }})</span>

              <div class="show-left" v-else>文件夹({{ parentList.length }})</div>
              <div class="show-right flex center pointer" @click="isShowAside = false">
                <img src="@/assets/image/case/case-show-left.png" /> 折叠
              </div>
            </div>
          </div>
          <div class="case-show-right pointer" v-else @click="isShowAside = true">
            <img src="@/assets/image/case/case-show-right.png" /> 展开
          </div>
          <!-- <div @click="isShowAside = !isShowAside" class="case-show">
					<i :class="isShowAside ? 'el-icon-caret-left' : 'el-icon-caret-right'" />
				</div> -->
          <el-scrollbar>
            <template v-if="caseIds.includes(breadcrumb.typeId)">
              <div @click="onAside(i)" v-for="(v, i) in parentList" :key="v.id" :class="{
                cur: v.id === breadcrumb.id,
                end: v.basicStatusId === 8,
                ing: v.basicStatusId !== 8,
              }" class="aside-item-second">
                <span class="item-tip">{{ v.basicStatusName }}</span>
                <p>{{ v.fullName }}</p>
              </div>
            </template>
            <template v-else>
              <div @click="onAside(i)" v-for="(v, i) in parentList" :key="v.id" :class="{ cur: v.id === breadcrumb.id }"
                class="aside-item">
                <p>{{ [96, 97, 98].includes(v.typeId) ? v.fullName : v.name }}</p>
              </div>
              <!-- <div
						  v-if="breadcrumbList[breadcrumbList.length - 2].showCreateFolder || breadcrumbList[breadcrumbList.length - 2].typeId === 0"
						  @click="createFolderByAside"
						  class="aside-add-item">
						  <p><i class="el-icon-plus mr10" />新增</p>
						</div> -->
            </template>
          </el-scrollbar>
        </div>
        <div :style="`width: ${firstParent.length
          ? '100%'
          : isShowAside
            ? 'calc(100% - 234px)'
            : 'calc(100% - 32px)'
          }`" class="case-content">
          <el-scrollbar :class="{ narrow: breadcrumb.typeId === 98 }" class="left">
            <div class="content-tabel">
              <div class="table-hd flex">
                <div class="firstParentChild flex wrap" v-if="firstParentChild.length">
                  <div class="item pointer" @click="changeFirstParentChild(item)"
                    :class="{ cur: item.id === firstParentChildInfo.id }" v-for="(item, index) in firstParentChild"
                    :key="index">
                    <p class="name">{{ item.name }} </p>
                    <p class="line"></p>
                  </div>
                </div>
                <span v-if="breadcrumb.typeId === 98" class="hd-title">文件夹 </span>
                <!-- <div v-else-if="[71,84].includes(breadcrumb.typeId)"
						      style="color:#FF6703">
						    输入密码后请按回车键提交，解锁成功的文件访问速度会加快
						  </div> -->
                <t-btn @click="onMarking" v-else-if="breadcrumb.typeId === 72" type="primary" w="106px" h="36px" fs="14px"
                  radius="8px">阅卷系统</t-btn>
                <i v-else />
                <div class="btn-box">
                  <t-btn @click="createXml" v-if="breadcrumb.typeId === 73" fs="14px" radius="8px" w="104px"
                    h="36px">新建导图</t-btn>
                  <div v-if="breadcrumb.showImage2pdf && image2pdfTaskCount > 0">
                    当前有<span style="color: #f56c6c"> {{ image2pdfTaskCount }} </span>个图片等待合成案卷
                    <!-- 当前有<span style="color: #F56C6C;"> {{ image2pdfTaskCount }} </span>个图片转换任务等待转换 -->
                    <el-button icon="el-icon-refresh" size="mini" circle @click="getList()" />
                  </div>
                  <template v-if="underMaterials()">
                    <t-btn @click="uploadFileDirct" fs="14px" radius="8px" w="120px" h="36px">上传案卷</t-btn>
                    <input @input="fileInput" ref="fileInput" type="file" style="display: none" multiple />
                    <t-btn @click="img2pdf" fs="14px" radius="8px" w="120px" h="36px">图片合成案卷</t-btn>
                    <t-btn @click="createFolder(breadcrumb)" fs="14px" radius="8px" w="120px" h="36px">创建案卷夹</t-btn>
                  </template>
                  <template v-else>
                    <t-btn @click="img2pdf" v-if="breadcrumb.showImage2pdf" fs="14px" radius="8px" w="120px"
                      h="36px">图片转PDF</t-btn>
                    <t-btn @click="uploadFile" v-if="breadcrumb.showUploadFile" type="danger" fs="14px" radius="8px"
                      w="104px" h="36px">上传文件</t-btn>
                  </template>
                  <template v-if="breadcrumb.showCreateFolder && !underMaterials()">
                    <t-btn @click="createYearFolder(breadcrumb.id)" v-if="yearParentIds.includes(breadcrumb.typeId)"
                      fs="14px" radius="8px" w="152px" h="36px">新建年份文件夹</t-btn>
                    <div class="year-btn" v-else>
                      <t-btn @click="createFolder(breadcrumb)" fs="14px" radius="8px" w="120px" h="36px">新建文件夹 </t-btn>

                      <t-btn v-if="firstChildYear.length && firstChildYearInfo.typeId == 83 && userInfo.is_group_admin == 1"
                        @click="delFolderCommit(firstChildYearInfo)" class="year-del" fs="14px" radius="8px" w="120px"
                        h="36px">删除年份文件夹 </t-btn>
                    </div>


                  </template>
                </div>
              </div>

              <div class="flex" v-if="firstChildYear.length > 0 && firstChildYear[0].typeId == 83">
                <el-scrollbar class="firstChildYear">
                  <div class="item pointer" v-if="firstChildYear.length && item.typeId == 83" @click="changeYear(item)"
                    :class="{ cur: item.id === firstChildYearInfo.id }" v-for="(item, index) in firstChildYear"
                    :key="index">
                    <p class="name">{{ item.name }}</p>
                  </div>
                </el-scrollbar>
                <t-btn @click="createYearFolder(firstParentChildInfo.id)"
                  v-if="yearParentIds.includes(firstParentChildInfo.typeId)" type="danger" fs="14px" radius="16px"
                  w="128px" h="32px" plain>新建年份文件夹 </t-btn>
              </div>
              <t-pagination @currentChange="currentChange" @sizeChange="sizeChange" :pages="pages">
                <el-table :data="list" slot="table" class="t-tabel" style="width: 100%" border>
                  <div slot="empty" class="nothing-box">
                    <i class="not-icon small" />
                  </div>
                  <el-table-column v-for="(v, i) in labelList" :key="i" :label="v.label" :min-width="v.width"
                    :prop="v.key" :sortable="v.sortable">
                    <template slot-scope="scope">
                      <el-tooltip :open-delay="500" v-if="v.key === 'name'" :content="[96, 97, 98].includes(scope.row.typeId)
                        ? scope.row.fullName || NOT
                        : scope.row[v.key] || NOT
                        " effect="dark" placement="top-start">
                        <span @click="onFile(scope.row)" class="pointer pointer__hover not-select ellipsis"
                          style="display: inline-block; width: 100%">
                          {{
                            [96, 97, 98].includes(scope.row.typeId)
                            ? scope.row.fullName || NOT
                            : scope.row[v.key] || NOT
                          }}
                        </span>
                      </el-tooltip>
                      <span v-else-if="v.key === 'no'">
                        {{
                          scope.$index + 1 + (pageParams.page - 1) * pageParams.perPage
                        }}
                      </span>
                      <span v-else-if="v.key === 'isFile'">
                        {{ scope.row[v.key] ? "文件" : "文件夹" }}
                      </span>
                      <span v-else-if="v.key === 'typeAndFrom'">
                        {{ !scope.row.isFile ? "文件夹" : scope.row.fileTypeName }} /
                        {{ scope.row.fromName }}
                      </span>
                      <span v-else>{{ scope.row[v.key] || NOT }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="breadcrumb.typeId !== 98" :width="getWidth(breadcrumb.typeId)" label="操作">
                    <template slot-scope="scope">
                      <span v-if="!scope.row.showOften &&
                        !scope.row.showPermission &&
                        !scope.row.showRename &&
                        !scope.row.isFile &&
                        !scope.row.showDelete
                        ">{{ NOT }}</span>
                      <div class="table-btn-box" v-else>
                        <t-btn @click="often(scope.row)" v-if="scope.row.showOften && !scope.row.isOften" type="primary"
                          fs="14px" isText>
                          设置常办</t-btn>
                        <t-btn @click="unoften(scope.row)" v-if="scope.row.showOften && scope.row.isOften" type="danger"
                          fs="14px" isText>
                          取消常办
                        </t-btn>
                        <t-btn @click="permiss(scope.row)" v-if="scope.row.showPermission && isCase" type="primary"
                          fs="14px" isText>
                          权限
                        </t-btn>
                        <t-btn @click="rename(scope.row)" v-if="scope.row.showRename" type="primary" fs="14px" isText>
                          重命名
                        </t-btn>

                        <!-- 思维导图不显示下载 -->
                        <t-btn @click="download(scope.row)" v-if="scope.row.isFile" type="primary" fs="14px" isText>
                          下载
                        </t-btn>
                        <t-btn @click="del(scope.row)" v-if="scope.row.showDelete" type="warning" fs="14px" isText>
                          删除
                        </t-btn>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </t-pagination>
              <logs @create="createWorkLogs" @edit="editWorkLogs" v-if="breadcrumb.typeId === 98" :casesId="breadcrumb.id"
                ref="logs" />
            </div>
          </el-scrollbar>
          <el-scrollbar v-if="breadcrumb.typeId === 98" class="right">
            <div class="collapse-right">案件基本信息</div>
            <el-collapse v-if="list && list.length" class="right-wp" accordion>
              <el-collapse-item title="案件基础信息">
                <el-form v-if="curEditKey === 'basicsParams'" class="t-form" size="medium" label-position="left"
                  @submit.native.prevent>
                  <el-form-item label="收案编号" class="less-mt" label-width="70px">
                    <el-input v-model="basicsParams.casesNo" placeholder="请输入事务所收案编号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="案件当前状态" label-width="100px">
                    <el-radio-group v-model="basicsParams.statusId">
                      <el-radio :label="7">在办</el-radio>
                      <el-radio :label="8">办结</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <div class="flex top-button">
                    <t-btn @click="saveDefendInfo('basicsParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" @submit.native.prevent>
                  <el-form-item label="收案编号" class="less-mt">
                    <span>{{ basicsParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="案件当前状态">
                    <span v-if="basicsParams.statusId">{{
                      basicsParams.statusId === 7
                      ? "在办"
                      : basicsParams.statusId === 8
                        ? "办结"
                        : NOT
                    }}</span>
                    <span v-else>{{ NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'basicsParams'" type="success" fs="12px" radius="17px"
                    w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="当事人信息">
                <el-form v-if="curEditKey === 'humanParams'" class="t-form" size="medium" label-position="left"
                  label-width="80px" @submit.native.prevent>
                  <el-form-item label="委托人姓名">
                    <el-input v-model="humanParams.entrustName" placeholder="请输入委托人姓名" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <el-input v-model="humanParams.entrustIdentity" placeholder="请输入委托人身份证号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="联系电话">
                    <el-input v-model="humanParams.phone" placeholder="请输入委托人联系电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="嫌疑人关系">
                    <el-input v-model="humanParams.relation" placeholder="请输入嫌疑人/被告人关系" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="嫌疑人">
                    <el-input v-model="humanParams.suspectName" placeholder="请输入嫌疑人" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <el-input v-model="humanParams.suspectIdentity" placeholder="请输入嫌疑人身份证号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="羁押地点">
                    <el-input v-model="humanParams.address" placeholder="请输入羁押地点" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="涉嫌罪名">
                    <el-select v-model="humanParams.crimeName" style="width: 100%" placeholder="请选择涉嫌罪名" multiple
                      filterable>
                      <el-option v-for="item in chargeList" :key="item.id" :label="item.name" :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="承办律师">
                    <el-input v-model="humanParams.lawyer" placeholder="请输入承办律师姓名" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="代理阶段">
                    <el-input v-model="humanParams.stageName" placeholder="请输入代理阶段" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <t-btn @click="saveDefendInfo('humanParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="委托人姓名">
                    <span>{{ humanParams.entrustName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <span>{{ humanParams.entrustIdentity || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="联系电话">
                    <span>{{ humanParams.phone || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="嫌疑人关系">
                    <span>{{ humanParams.relation || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="嫌疑人">
                    <span>{{ humanParams.suspectName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <span>{{ humanParams.suspectIdentity || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="羁押地点">
                    <span>{{ humanParams.address || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="涉嫌罪名">
                    <span v-if="humanParams.crimeName && humanParams.crimeName.length">{{
                      humanParams.crimeName.join(",")
                    }}</span>
                    <span v-else>{{ NOT }}</span>
                  </el-form-item>
                  <el-form-item label="承办律师">
                    <span>{{ humanParams.lawyer || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="代理阶段">
                    <span>{{ humanParams.stageName || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'humanParams'" type="success" fs="12px" radius="17px"
                    w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="侦查阶段">
                <el-form v-if="curEditKey === 'examineParams'" class="t-form" size="medium" label-position="left"
                  label-width="90px" @submit.native.prevent>
                  <el-form-item label="侦查机关">
                    <el-input v-model="examineParams.examineSupervision" placeholder="请输入侦查机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="经办人">
                    <el-input v-model="examineParams.operatorName" placeholder="请输入经办人姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <el-date-picker v-model="examineParams.filingDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择立案时间">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="拘留时间">
                    <el-date-picker v-model="examineParams.detentionDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择拘留时间">
                    </el-date-picker>
                  </el-form-item>
                  <div class="flex ai_c mb14">
                    <span style="flex: 1; height: 1px; background: #e4e7ed"></span>
                    <span style="
                        width: 90px;
                        color: #333;
                        text-align: center;
                        font-weight: bold;
                      ">批捕阶段</span>
                    <span style="flex: 1; height: 1px; background: #e4e7ed"></span>
                  </div>
                  <el-form-item label="检察机关">
                    <el-input v-model="examineParams.supervision" placeholder="请输入检查机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="检察官">
                    <el-input v-model="examineParams.procurator" placeholder="请输入检察官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="呈捕时间">
                    <el-date-picker v-model="examineParams.arrestDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请输入呈捕时间">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="批捕情况">
                    <el-input v-model="examineParams.arrestCondition" placeholder="请输入批捕情况" maxlength="70" />
                  </el-form-item>
                  <div class="mb20" style="height: 1px; background: #e4e7ed" />
                  <el-form-item label="取保侯审">
                    <el-date-picker v-model="examineParams.awaitDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择取保侯审时间">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="监视居住">
                    <el-date-picker v-model="examineParams.monitorDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择监视居住时间">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="侦查延期">
                    <el-input v-model="examineParams.delayCondition" placeholder="请输入侦查延期情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <t-btn @click="saveDefendInfo('examineParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="90px"
                  @submit.native.prevent>
                  <el-form-item label="侦查机关">
                    <span>{{ examineParams.examineSupervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="经办人">
                    <span>{{ examineParams.operatorName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <span>{{ examineParams.filingDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="拘留时间">
                    <span>{{ examineParams.detentionDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <div class="flex ai_c mb14">
                    <span style="flex: 1; height: 1px; background: #e4e7ed"></span>
                    <span style="
                        width: 90px;
                        color: #333;
                        text-align: center;
                        font-weight: bold;
                      ">批捕阶段</span>
                    <span style="flex: 1; height: 1px; background: #e4e7ed"></span>
                  </div>
                  <el-form-item label="检察机关">
                    <span>{{ examineParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="检察官">
                    <span>{{ examineParams.procurator || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="呈捕时间">
                    <span>{{ examineParams.arrestDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="批捕情况">
                    <span>{{ examineParams.arrestCondition || NOT }}</span>
                  </el-form-item>
                  <div class="mb20" style="height: 1px; background: #e4e7ed" />
                  <el-form-item label="取保侯审">
                    <span>{{ examineParams.awaitDate || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="监视居住">
                    <span>{{ examineParams.monitorDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="侦查延期">
                    <span>{{ examineParams.delayCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'examineParams'" type="success" fs="12px" radius="17px"
                    w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="审查起诉阶段">
                <el-form v-if="curEditKey === 'prosecutionParams'" class="t-form" size="medium" label-position="left"
                  label-width="100px" @submit.native.prevent>
                  <el-form-item label="检察机关">
                    <el-input v-model="prosecutionParams.supervision" placeholder="请输入检察机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="受理时间">
                    <el-date-picker v-model="prosecutionParams.acceptDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择受理时间" />
                  </el-form-item>
                  <el-form-item label="检察官">
                    <el-input v-model="prosecutionParams.procurator" placeholder="请输入检察官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="退查情况">
                    <el-input v-model="prosecutionParams.retreatCondition" placeholder="请输入退查情况" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="结案情况">
                    <el-input v-model="prosecutionParams.finalCondition" placeholder="请输入结案情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <t-btn @click="saveDefendInfo('prosecutionParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="检察机关">
                    <span>{{ prosecutionParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="受理时间">
                    <span>{{ prosecutionParams.acceptDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="检察官">
                    <span>{{ prosecutionParams.procurator || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="退查情况">
                    <span>{{ prosecutionParams.retreatCondition || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="结案情况">
                    <span>{{ prosecutionParams.finalCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'prosecutionParams'" type="success" fs="12px"
                    radius="17px" w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="一审">
                <el-form v-if="curEditKey === 'trialFirstParams'" class="t-form" size="medium" label-position="left"
                  label-width="100px" @submit.native.prevent>
                  <el-form-item label="案号">
                    <el-input v-model="trialFirstParams.casesNo" placeholder="请输入案号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <el-date-picker v-model="trialFirstParams.filingDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择立案时间" />
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <el-input v-model="trialFirstParams.supervision" placeholder="请输入审判机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="法官">
                    <el-input v-model="trialFirstParams.judgeName" placeholder="请输入法官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="书记员">
                    <el-input v-model="trialFirstParams.clerkName" placeholder="请输入书记员姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <el-date-picker v-model="trialFirstParams.courtDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择开庭时间" />
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <el-input v-model="trialFirstParams.delayCondition" placeholder="请输入延期情况" maxlength="70" />
                    <!-- <el-date-picker
			              v-model="trialFirstParams.delayDate"
			              style="width: 100%"
			              type="date"
			              value-format="yyyy-MM-dd"
			              placeholder="请选择延期时间" /> -->
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <el-input v-model="trialFirstParams.finalCondition" placeholder="请输入判决情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <!-- <t-btn
								type="info"
			              @click="curEditKey = ''"
								fs="12px"
								radius="17px"
			              w="50px"
			              h="24px"
			              class="mr10">
			              取消
			            </t-btn> -->
                    <t-btn @click="saveDefendInfo('trialFirstParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="案号">
                    <span>{{ trialFirstParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <span>{{ trialFirstParams.filingDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <span>{{ trialFirstParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="法官">
                    <span>{{ trialFirstParams.judgeName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="书记员">
                    <span>{{ trialFirstParams.clerkName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <span>{{ trialFirstParams.courtDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <span>{{ trialFirstParams.delayCondition || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <span>{{ trialFirstParams.finalCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'trialFirstParams'" type="success" fs="12px"
                    radius="17px" w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="二审">
                <el-form v-if="curEditKey === 'trialSecondParams'" class="t-form" size="medium" label-position="left"
                  label-width="100px" @submit.native.prevent>
                  <el-form-item label="案号">
                    <el-input v-model="trialSecondParams.casesNo" placeholder="请输入案号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <el-date-picker v-model="trialSecondParams.filingDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择立案时间" />
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <el-input v-model="trialSecondParams.supervision" placeholder="请输入审判机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="法官">
                    <el-input v-model="trialSecondParams.judgeName" placeholder="请输入法官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="书记员">
                    <el-input v-model="trialSecondParams.clerkName" placeholder="请输入书记员姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <el-date-picker v-model="trialSecondParams.courtDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择开庭时间" />
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <el-input v-model="trialSecondParams.delayCondition" placeholder="请输入延期情况" maxlength="70" />
                    <!-- <el-date-picker
			              v-model="trialSecondParams.delayDate"
			              style="width: 100%"
			              type="date"
			              value-format="yyyy-MM-dd"
			              placeholder="请选择延期时间" /> -->
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <el-input v-model="trialSecondParams.finalCondition" placeholder="请输入判决情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <!-- <t-btn
								type="info"
			              @click="curEditKey = ''"
								fs="12px"
								radius="17px"
			              w="50px"
			              h="24px"
			              class="mr10">
			              取消
			            </t-btn> -->
                    <t-btn @click="saveDefendInfo('trialSecondParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="案号">
                    <span>{{ trialSecondParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <span>{{ trialSecondParams.filingDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <span>{{ trialSecondParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="法官">
                    <span>{{ trialSecondParams.judgeName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="书记员">
                    <span>{{ trialSecondParams.clerkName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <span>{{ trialSecondParams.courtDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <span>{{ trialSecondParams.delayCondition || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <span>{{ trialSecondParams.finalCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'trialSecondParams'" type="success" fs="12px"
                    radius="17px" w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="重审一审">
                <el-form v-if="curEditKey === 'trialFirstRepeatParams'" class="t-form" size="medium" label-position="left"
                  label-width="100px">
                  <el-form-item label="案号">
                    <el-input v-model="trialFirstRepeatParams.casesNo" placeholder="请输入案号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <el-date-picker v-model="trialFirstRepeatParams.filingDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择立案时间" />
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <el-input v-model="trialFirstRepeatParams.supervision" placeholder="请输入审判机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="法官">
                    <el-input v-model="trialFirstRepeatParams.judgeName" placeholder="请输入法官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="书记员">
                    <el-input v-model="trialFirstRepeatParams.clerkName" placeholder="请输入书记员姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <el-date-picker v-model="trialFirstRepeatParams.courtDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择开庭时间" />
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <el-input v-model="trialFirstRepeatParams.delayCondition" placeholder="请输入延期情况" maxlength="70" />
                    <!-- <el-date-picker
			              v-model="trialFirstRepeatParams.delayDate"
			              style="width: 100%"
			              type="date"
			              value-format="yyyy-MM-dd"
			              placeholder="请选择延期时间" /> -->
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <el-input v-model="trialFirstRepeatParams.finalCondition" placeholder="请输入判决情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <!-- <t-btn
								type="info"
			              @click="curEditKey = ''"
								fs="12px"
								radius="17px"
			              w="50px"
			              h="24px"
			              class="mr10">
			              取消
			            </t-btn> -->
                    <t-btn @click="saveDefendInfo('trialFirstRepeatParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="案号">
                    <span>{{ trialFirstRepeatParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <span>{{
                      trialFirstRepeatParams.filingDate.split(" ")[0] || NOT
                    }}</span>
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <span>{{ trialFirstRepeatParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="法官">
                    <span>{{ trialFirstRepeatParams.judgeName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="书记员">
                    <span>{{ trialFirstRepeatParams.clerkName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <span>{{
                      trialFirstRepeatParams.courtDate.split(" ")[0] || NOT
                    }}</span>
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <span>{{ trialFirstRepeatParams.delayCondition || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <span>{{ trialFirstRepeatParams.finalCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'trialFirstRepeatParams'" type="success" fs="12px"
                    radius="17px" w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="重审二审">
                <el-form v-if="curEditKey === 'trialSecondRepeatParams'" class="t-form" size="medium"
                  label-position="left" label-width="100px" @submit.native.prevent>
                  <el-form-item label="案号">
                    <el-input v-model="trialSecondRepeatParams.casesNo" placeholder="请输入案号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <el-date-picker v-model="trialSecondRepeatParams.filingDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择立案时间" />
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <el-input v-model="trialSecondRepeatParams.supervision" placeholder="请输入审判机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="法官">
                    <el-input v-model="trialSecondRepeatParams.judgeName" placeholder="请输入法官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="书记员">
                    <el-input v-model="trialSecondRepeatParams.clerkName" placeholder="请输入书记员姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <el-date-picker v-model="trialSecondRepeatParams.courtDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择开庭时间" />
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <el-input v-model="trialSecondRepeatParams.delayCondition" placeholder="请输入延期情况" maxlength="70" />
                    <!-- <el-date-picker
			              v-model="trialSecondRepeatParams.delayDate"
			              style="width: 100%"
			              type="date"
			              value-format="yyyy-MM-dd"
			              placeholder="请选择延期时间" /> -->
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <el-input v-model="trialSecondRepeatParams.finalCondition" placeholder="请输入判决情况" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <!-- <t-btn
								type="info"
			              @click="curEditKey = ''"
								fs="12px"
								radius="17px"
			              w="50px"
			              h="24px"
			              class="mr10">
			              取消
			            </t-btn> -->
                    <t-btn @click="saveDefendInfo('trialSecondRepeatParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="案号">
                    <span>{{ trialSecondRepeatParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间">
                    <span>{{
                      trialSecondRepeatParams.filingDate.split(" ")[0] || NOT
                    }}</span>
                  </el-form-item>
                  <el-form-item label="审判机关">
                    <span>{{ trialSecondRepeatParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="法官">
                    <span>{{ trialSecondRepeatParams.judgeName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="书记员">
                    <span>{{ trialSecondRepeatParams.clerkName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="开庭时间">
                    <span>{{
                      trialSecondRepeatParams.courtDate.split(" ")[0] || NOT
                    }}</span>
                  </el-form-item>
                  <el-form-item label="延期情况">
                    <span>{{ trialSecondRepeatParams.delayCondition || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="判决情况">
                    <span>{{ trialSecondRepeatParams.finalCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'trialSecondRepeatParams'" type="success" fs="12px"
                    radius="17px" w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
              <el-collapse-item title="刑事申诉阶段">
                <el-form v-if="curEditKey === 'appealParams'" class="t-form" size="medium" label-position="left"
                  label-width="100px" @submit.native.prevent>
                  <el-form-item label="案号">
                    <el-input v-model="appealParams.casesNo" placeholder="请输入案号" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="受理时间">
                    <el-date-picker v-model="appealParams.acceptDate" style="width: 100%" type="date"
                      value-format="yyyy-MM-dd" placeholder="请选择受理时间" />
                  </el-form-item>
                  <el-form-item label="受理机关">
                    <el-input v-model="appealParams.supervision" placeholder="请输入受理机关" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="法官">
                    <el-input v-model="appealParams.judgeName" placeholder="请输入法官姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="书记员">
                    <el-input v-model="appealParams.clerkName" placeholder="请输入书记员姓名/电话" maxlength="70" />
                  </el-form-item>
                  <el-form-item label="申诉结果">
                    <el-input v-model="appealParams.appealCondition" placeholder="请输入申诉结果" maxlength="70" />
                  </el-form-item>
                  <div class="flex top-button">
                    <!-- <t-btn
								type="info"
			              @click="curEditKey = ''"
								fs="12px"
								radius="17px"
			              w="50px"
			              h="24px"
			              class="mr10">
			              取消
			            </t-btn> -->
                    <t-btn @click="saveDefendInfo('appealParams')" fs="12px" radius="17px" w="50px" h="24px">
                      保存
                    </t-btn>
                  </div>
                </el-form>
                <el-form v-else class="t-form" size="medium" label-position="left" label-width="100px"
                  @submit.native.prevent>
                  <el-form-item label="案号">
                    <span>{{ appealParams.casesNo || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="受理时间">
                    <span>{{ appealParams.acceptDate.split(" ")[0] || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="受理机关">
                    <span>{{ appealParams.supervision || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="法官">
                    <span>{{ appealParams.judgeName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="书记员">
                    <span>{{ appealParams.clerkName || NOT }}</span>
                  </el-form-item>
                  <el-form-item label="申诉结果">
                    <span>{{ appealParams.appealCondition || NOT }}</span>
                  </el-form-item>
                  <t-btn class="top-button" @click="curEditKey = 'appealParams'" type="success" fs="12px" radius="17px"
                    w="54px" h="24px" plain>
                    编辑
                  </t-btn>
                </el-form>
              </el-collapse-item>
            </el-collapse>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <el-dialog title="图片查看" :visible.sync="imgPreview" width="500px">
      <img :src="showImage" alt="" style="width: 100%; height: 300px" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgPreview = false">确 定</el-button>
        <el-button type="primary" @click="downLoadImage">下 载</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置密码" :visible.sync="passwordVisible" width="500px">
      <el-input v-model="password" class="input-width" placeholder="请输入设置的密码(无密码可直接提交)" />
      <div class="flex jc_e ai_c pt20">
        <t-btn @click="passwordVisible = false" class="bold" isText>取消</t-btn>
        <t-btn @click="comfirmPassword" w="78px" h="36px" class="bold ml30">确认</t-btn>
      </div>
    </el-dialog>

    <create-folder @commit="createFolderCommit" ref="createFolder" />
    <!-- <del-folder @commit="delFolderCommit" ref="delFolder" /> -->


    <create-year-folder @commit="createYearFolderCommit" ref="createYearFolder" />
    <rename-dialog @commit="reanmeCommit" ref="renameDialog" />
    <rename-year-dialog @commit="reanmeCommit" ref="renameYearDialog" />
    <permiss-dialog @commit="peimissCommit" ref="permissDialog" />
    <create-defend @commit="createDefendCommit" :chargeList="chargeList" ref="createDefend" />
    <rename-defend @commit="renameDefendCommit" :chargeList="chargeList" ref="renameDefend" />
    <create-report @commit="createReportCommit" :chargeList="chargeList" ref="createReport" />
    <rename-report @commit="renameReportCommit" :chargeList="chargeList" ref="renameReport" />
    <create-litigation @commit="createLitigationCommit" ref="createLitigation" />
    <rename-litigation @commit="renameLitigationCommit" ref="renameLitigation" />
    <create-work-logs @commit="createWorkLogsCommit" ref="createWorkLogs" />
    <edit-work-logs @commit="editWorkLogsCommit" ref="editWorkLogs" />
    <upload-file @createTemplate="createTemplateFile" @createBlank="createBlankFile" @upload="uploadFileChange"
      @uploadFail="uploadFail" @afterSelectFile="afterSelectFile" ref="uploadFile" />
    <create-pdf @commit="img2pdfCommit" ref="createPdf" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { download } from "@/assets/javascript/util";
import message from "@/mixins/message";
import pagination from "@/mixins/pagination";
import createFolder from "./components/create-folder";
import delFolder from "./components/del-folder";
import createYearFolder from "./components/create-year-folder";
import renameDialog from "./components/rename-dialog";
import renameYearDialog from "./components/rename-year-dialog";
import permissDialog from "./components/permiss-dialog";
import createDefend from "./components/create-defend";
import renameDefend from "./components/rename-defend";
import createReport from "./components/create-report";
import renameReport from "./components/rename-report";
import createLitigation from "./components/create-litigation";
import renameLitigation from "./components/rename-litigation";
import logs from "./components/logs";
import createWorkLogs from "./components/create-work-logs";
import editWorkLogs from "./components/edit-work-logs";
import uploadFile from "./components/upload-file";
import createPdf from "./components/create-pdf";
const crypto = require("crypto");

const ininBreadcrumb = () => ({
  name: "案件中心",
  id: 0,
  typeId: 0,
});

export default {
  mixins: [pagination, message],
  components: {
    delFolder,
    createFolder,
    renameDialog,
    createYearFolder,
    renameYearDialog,
    permissDialog,
    createDefend,
    renameDefend,
    createReport,
    renameReport,
    createLitigation,
    renameLitigation,
    logs,
    createWorkLogs,
    editWorkLogs,
    uploadFile,
    createPdf,
  },
  data() {
    return {
      isShowAside: true,
      imgPreview: false, //图片预览弹窗
      showImage: "", //预览图片链接
      showImageName: '',
      defalutLabelList: [
        {
          label: "名称",
          width: "350",
          key: "name",
        },
        {
          label: "类型/来源",
          width: "120",
          key: "typeAndFrom",
        },
        // {
        //   label: '类型',
        //   width: '100',
        //   // key: 'isFile'
        //   key: 'fileTypeName'
        // }, {
        //   label: '来源',
        //   width: '100',
        //   key: 'fromName'
        // },
        {
          label: "修改时间",
          width: "180",
          key: "updateDate",
          sortable: true,
        },
      ],
      yearLabelList: [
        {
          label: "名称",
          width: "280",
          key: "name",
        },
        {
          label: "案件数",
          width: "120",
          key: "count",
        },
        {
          label: "修改时间",
          width: "180",
          key: "updateDate",
          sortable: true,
        },
      ],
      caseLabelList: [
        {
          label: "名称",
          width: "280",
          key: "name",
        },
        {
          label: "修改时间",
          width: "180",
          key: "updateDate",
          sortable: true,
        },
        {
          label: "文件大小",
          width: "120",
          key: "size",
        },
      ],
      spLabelList: [
        {
          label: "编号",
          width: "100",
          key: "no",
        },
        {
          label: "文件名",
          width: "300",
          key: "name",
        },
        {
          label: "修改时间",
          width: "180",
          key: "updateDate",
          sortable: true,
        },
      ],
      loading: 0,
      breadcrumbList: [ininBreadcrumb()],
      passwordVisible: false, //设置密码弹窗的显示隐藏
      password: "", //文件/文件夹密码
      rowItem: null, //当前操作行数据
      cur: 0,
      parentList: [],
      // 辩护
      yearParentIds: [86],
      // 年份
      yearIds: [83],
      // 辩护罪案
      caseIds: [98],
      // 罪名
      chargeList: [],
      // 辩护案件基础信息
      basicsParams: {
        statusId: "",
        casesNo: "",
      },
      // 辩护案件当事人信息
      humanParams: {
        entrustName: "",
        entrustIdentity: "",
        phone: "",
        relation: "",
        suspectName: "",
        suspectIdentity: "",
        address: "",
        crimeName: [],
        lawyer: "",
        stageName: "",
      },
      // 辩护案件侦查阶段信息
      examineParams: {
        examineSupervision: "",
        operatorName: "",
        filingDate: "",
        detentionDate: "",
        supervision: "",
        procurator: "",
        arrestDate: "",
        arrestCondition: "",
        awaitDate: "",
        monitorDate: "",
        delayCondition: "",
      },
      // 辩护案件起诉阶段信息
      prosecutionParams: {
        supervision: "",
        acceptDate: "",
        procurator: "",
        retreatCondition: "",
        finalCondition: "",
      },
      // 辩护案件1审信息
      trialFirstParams: {
        casesNo: "",
        filingDate: "",
        supervision: "",
        judgeName: "",
        clerkName: "",
        courtDate: "",
        // delayDate: '',
        delayCondition: "",
        finalCondition: "",
      },
      // 辩护案件2审信息
      trialSecondParams: {
        casesNo: "",
        filingDate: "",
        supervision: "",
        judgeName: "",
        clerkName: "",
        courtDate: "",
        // delayDate: '',
        delayCondition: "",
        finalCondition: "",
      },
      // 辩护案件重1审信息
      trialFirstRepeatParams: {
        casesNo: "",
        filingDate: "",
        supervision: "",
        judgeName: "",
        clerkName: "",
        courtDate: "",
        // delayDate: '',
        delayCondition: "",
        finalCondition: "",
      },
      // 辩护案件重2审信息
      trialSecondRepeatParams: {
        casesNo: "",
        filingDate: "",
        supervision: "",
        judgeName: "",
        clerkName: "",
        courtDate: "",
        // delayDate: '',
        delayCondition: "",
        finalCondition: "",
      },
      // 辩护案件申诉阶段信息
      appealParams: {
        casesNo: "",
        acceptDate: "",
        supervision: "",
        judgeName: "",
        clerkName: "",
        appealCondition: "",
      },
      curEditKey: "",
      image2pdfTaskCount: 0,
      timer: null,

      firstParent: [],
      firstParentInfo: {},
      firstParentChild: [],
      firstParentChildInfo: {},
      firstChildYear: [],
      firstChildYearInfo: {},
    };
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),


    breadcrumb() {
      const { breadcrumbList } = this;

      return breadcrumbList[breadcrumbList.length - 1];
    },
    labelList() {
      const { breadcrumb } = this;
      const id = breadcrumb.typeId;


      if (!id) {
        return null;
      }
      if (this.yearParentIds.includes(id)) {
        return this.yearLabelList;
      } else if (this.yearIds.includes(id)) {
        return this.caseLabelList;
      } else if (this.caseIds.includes(id)) {
        return this.spLabelList;
      } else {

        return this.defalutLabelList;
      }
    },
    isCase() {
      const { breadcrumbList } = this;
      const arr = breadcrumbList.map((v) => v.typeId);

      if (arr.includes(96) || arr.includes(97) || arr.includes(98)) {
        return false;
      }

      return true;
    },
  },
  watch: {
    curGroupId(val) {
      if (val) {
        this.cur = 0;
        this.init();
      }
    },
  },
  activated() {
    this.init();
  },
  methods: {
    ...mapMutations(["SETMSGDIALOG", "SETLOADING", "SETMSG"]),
    init() {
      this.pageParams.page = 1;
      this.getList();
    },
    // 新增年份文件夹
    createYearFolder(parentId) {
      this.$refs.createYearFolder.show(parentId);
    },
    // 新增年份文件夹请求
    createYearFolderCommit(params) {
      this.$api.cases.createYearFolder(params).then(() => {
        this.init();
        this.$refs.createYearFolder.visible = false;
      });
    },
    getWidth(id) {
      if ([83, 87, 95].includes(id)) {
        return 280;
      } else if (id == 71 || id == 84) {
        return 200;
      } else {
        return 200;
      }
    },
    // 删除
    del(row) {
      this.SETMSGDIALOG({
        title: "系统提示",
        message: `删除后将移入【回收站】，确定要该${row.isFile ? "文件" : "文件夹"
          }删除吗？`,
        width: "439px",
        type: "error",
        commit: () => {
          const api = row.isFile ? "deleteFile" : "deleteFolder";

          this.$api.cases[api]({
            id: row.id,
          }).then(() => {
            if (this.list.length <= 1 && this.pageParams.page > 1) {
              this.pageParams.page = this.pageParams.page - 1;
            }
            this.getList();
            this.SETMSGDIALOG(null);
          });
        },
      });
    },
    // 重命名接口
    reanmeCommit(params) {
      let that = this;
      const api = params.isFile ? "renameByFile" : "renameByFolder";

      this.$api.cases[api](params).then(() => {
        that.init();
        that.$refs.renameDialog.visible = false;
        that.$refs.renameYearDialog.visible = false;
      });
    },
    // 重命名
    rename(row) {
      const { yearIds } = this;
      if (row.typeId === 98) {
        this.$refs.renameDefend.show(row);
      } else if (row.typeId === 96) {
        this.$refs.renameReport.show(row);
      } else if (row.typeId === 97) {
        this.$refs.renameLitigation.show(row);
      } else if (yearIds.includes(row.typeId)) {
        this.$refs.renameYearDialog.show(row.name, { id: row.id, isFile: row.isFile });
      } else {
        this.$refs.renameDialog.show(row.name, { id: row.id, isFile: row.isFile });
      }
    },
    // 侧边栏创建文件
    createFolderByAside() {
      const { breadcrumbList, parentList, yearParentIds } = this;
      const { typeId, id } = breadcrumbList[breadcrumbList.length - 2];

      if (typeId === 0) {
        this.createFolder({ id: parentList[0].parentId, typeId });
      } else if (typeId === 87) {
        this.$refs.createReport.show(id);
      } else if (typeId === 95) {
        this.$refs.createLitigation.show(id);
      } else if (yearParentIds.includes(typeId)) {
        this.createYearFolder(id);
      } else {
        this.createFolder({ typeId, id });
      }
    },
    // 创建文件夹
    createFolder({ id, typeId }) {
      if (typeId === 87) {
        this.$refs.createReport.show(id);
      } else if (typeId === 95) {
        this.$refs.createLitigation.show(id);
      } else if (typeId === 83) {
        this.$refs.createDefend.show(id, this.breadcrumb.name);
      } else {
        this.$refs.createFolder.show(id);
      }
    },
    // 创建文件夹删除
    delFolderCommit(params) {
      let that = this
      let firstChildYear = this.firstChildYear

      if (params.id == firstChildYear[0].id) {

        this.firstChildYearInfo = firstChildYear[1]
        this.cur = firstChildYear[1].id
      } else {
        this.firstChildYearInfo = firstChildYear[0]
        this.cur = firstChildYear[0].id
      }
      this.SETMSGDIALOG({
        title: "系统提示",
        message: `删除后将移入【回收站】，确定要删除该【${params.name}】年份文件夹吗？`,
        width: "439px",
        type: "error",
        commit: () => {
        
          this.$confirm('再次确认删除？')
            .then(_ => {
              this.$api.cases.deleteFolder({
                id: params.id,
              }).then(() => {
                if (this.list.length <= 1 && this.pageParams.page > 1) {
                  this.pageParams.page = this.pageParams.page - 1;
                }
                this.getList();
                this.SETMSGDIALOG(null);
              });
              done();
            })
            .catch(_ => { 
              this.SETMSGDIALOG(null);
            });
        },
      });
    },

    // 创建文件夹请求
    createFolderCommit(params) {
      this.$api.cases.createFolder(params).then(() => {
        this.init();
        this.$refs.createFolder.visible = false;
      });
    },
    // 设置密码弹窗
    setPassword(row) {
      this.rowItem = row;
      this.passwordVisible = true;
    },
    pdfToImage({ id }) {
      this.$api.cases.pdfToimage({ id }).then(() => {
        this.init();
      });
    },
    // 设置密码请求
    async comfirmPassword() {
      const {
        rowItem: { isFile, id },
      } = this;
      let { password } = this;
      let params = {
        id,
        password,
      };
      if (isFile) {
        await this.$api.cases.setFilePassword(params);
      } else {
        await this.$api.cases.setDirPassword(params);
      }
      this.init();
      this.passwordVisible = false;
      this.rowItem = null;
      this.password = "";
    },
    // getDataList
    getList() {
      const { pageParams, cur } = this;
      const apiList = [this.getDirectory(), this.getBreadcrumb(), this.templateList()];

      this.loading = this.loading + 1;

      Promise.all(apiList)
        .then((res) => {
          const [directoryRes, breadcrumbRes, templateListRes] = res;
          let curParams = directoryRes.parent.filter((v) => v.id === cur)[0];
          if (pageParams.page === 1) {
            const curParent = directoryRes.parent.filter((v) => v.id === cur)[0];
            this.parentList = directoryRes.parent;

            if (curParent && curParent.typeId === 98) {
              console.log('typeId === 98')
              this.getDefendInfo();
            }



            const firstParentInfo = breadcrumbRes.items[0];
         
            const parent = directoryRes.parent && directoryRes.parent[0];



            if (firstParentInfo && firstParentInfo.typeId == 81) {
              if (breadcrumbRes.items.length == 1) {
                this.firstParentInfo = firstParentInfo;
                this.firstParent = directoryRes.firstParent;

                this.firstParentChild = directoryRes.items.slice(0, 1);
                this.firstParentChildInfo = directoryRes.items && directoryRes.items[0];

                this.pageParams.perPage = 20;
                this.cur = this.firstParentChildInfo.id;
                this.init();
              }
              if (breadcrumbRes.items.length == 2) {
                this.pageParams.perPage = 10;
                if (breadcrumbRes.items[1].name === "辩护") {
                  this.firstParent = directoryRes.firstParent;
                  this.firstParentInfo = firstParentInfo;

                  this.firstParentChild = directoryRes.parent.slice(0, 4);
                  if (!this.firstParentChildInfo.id) {
                    this.firstParentChildInfo = parent;
                  }

                  this.firstChildYear = directoryRes.items;

                  this.firstChildYearInfo = directoryRes.items && directoryRes.items[0];
                  this.cur = this.firstChildYearInfo.id;
                  this.init();
                } else {
                  this.firstParentInfo = {};
                  this.firstParent = [];

                  this.firstParentChildInfo = {};
                  this.firstParentChild = [];

                  this.firstChildYearInfo = {};
                  this.firstChildYear = [];
                  // this.firstParent = directoryRes.firstParent
                  // this.firstParentInfo = firstParentInfo

                  // this.firstParentChild = directoryRes.parent.slice(0,4)
                  // if(!this.firstParentChildInfo.id){
                  // 	this.firstParentChildInfo = parent
                  // }

                  // this.firstChildYear = directoryRes.items

                  // this.firstChildYearInfo = directoryRes.items && directoryRes.items[0]
                  // this.cur = this.firstChildYearInfo.id
                  // this.init()
                }
              } else if (breadcrumbRes.items.length == 3) {

                // this.pageParams.perPage = 10
                this.pageParams.perPage = 10;
              } else {
                this.pageParams.perPage = 10;
                this.firstParentInfo = {};
                this.firstParent = [];

                this.firstParentChildInfo = {};
                this.firstParentChild = [];

                this.firstChildYearInfo = {};
                this.firstChildYear = [];
              }
            } else {
              this.pageParams.perPage = 10;
              this.firstParentInfo = {};
              this.firstParent = [];

              this.firstParentChildInfo = {};
              this.firstParentChild = [];

              this.firstChildYearInfo = {};
              this.firstChildYear = [];
            }
          }
          console.log(curParams)
          if (curParams.typeId == 99) {
         
            // this.firstChildYear = templateListRes.parent;
            this.list = templateListRes.items;
            this.pages = templateListRes.page;


          } else {
       
            this.firstChildYear = directoryRes.parent;
            this.list = directoryRes.items;
            this.pages = directoryRes.page;
          }


          this.$nextTick(() => {
            if (this.breadcrumb.typeId === 98) {
              this.pages.totalCount = -1;
            }
          });
          this.breadcrumbList = [ininBreadcrumb(), ...breadcrumbRes.items];
          if (this.breadcrumb.showImage2pdf) {
            this.getImage2pdfTask();
          }

          if (this.loading > 0) {
            this.loading = this.loading - 1;
          }
          this.getStatus();


        })
        .catch((err) => {
          if (this.loading > 0) {
            this.loading = this.loading - 1;
          }
        });
    },

    //获取列表转换状态（案卷材料下列表）
    getStatus() {
      if ([71, 98].includes(this.breadcrumb.typeId)) {
        this.$api.cases.transformStatu({ parentId: this.cur }).then((res) => {
          this.list.forEach((item) => {
            this.$set(item, "transformStatu", "输入密码");
          });
          if (res && res.filter) {
            res.forEach((r) => {
              let sel = this.list.find((l) => l.id == r.fileId);
              if (sel) {
                this.$set(sel, "transformStatu", r.statusName);
                let { statusId } = r;
                if (statusId !== 6) {
                  this.$set(sel, "password", r.password);
                }
                this.$set(sel, "statusId", statusId);
              }
            });
            let done = res.filter((f) => !f.statusIsFinish);
            if (done.length > 0) {
              this.timer = setTimeout(() => {
                this.getStatus();
              }, 1000 * 60);
            }
          }
        });
      }
    },
    // 获取数据
    getDirectory() {
      const { pageParams, cur } = this;

      return new Promise((resolve, reject) => {
        this.$api.cases
          .getDirectory({
            ...pageParams,
            parentId: cur || 0,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 获取数据
    templateList() {
      const { pageParams, cur } = this;

      return new Promise((resolve, reject) => {
        this.$api.cases
          .getTemplateList({
            ...pageParams,
            //  parentId: cur || 0,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getBreadcrumb() {
      const { cur } = this;

      return new Promise((resolve, reject) => {
        this.$api.cases
          .getBreadcrumb({
            parentId: cur || 0,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    /**
     * @description 判断当前列表是否在案卷材料材料下
     */
    underMaterials() {
      return this.breadcrumbList.some((b) => b.typeId == 71);
    },
    getImage2pdfTask() {
      const { cur } = this;
      this.$api.cases
        .getImage2pdfTask({
          parentId: cur || 0,
        })
        .then((res) => {
          this.image2pdfTaskCount = res.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击左边栏
    onAside(i) {
      const { parentList, cur } = this;

      if (parentList[i].id === cur) {
        return;
      }
      let id = parentList[i].id;
      this.$router.replace({
        name: "case",
        query: { id },
      });

      this.cur = parentList[i].id;
      this.init();
    },
    //点击一级
    changeFirstParent(row) {
      if (this.firstParentInfo.id == row.id) return;
      // this.firstParentInfo = row
      let id = row.id;
      this.$router.replace({
        name: "case",
        query: { id },
      });

      this.cur = row.id;
      this.init();
    },
    //点击二级
    changeFirstParentChild(row) {
      if (this.firstParentChildInfo.id == row.id) return;
      // this.firstParentChildInfo = row
      let id = row.id;
      this.$router.replace({
        name: "case",
        query: { id },
      });

      this.cur = row.id;
      this.init();
    },
    //点击年
    changeYear(row) {
      if (this.cur == row.id) return;
      this.firstChildYearInfo = row;
      this.cur = row.id;
      this.init();
    },
    // 点击列表文件
    onFile(row) {
     
      if (row.typeId === 99 && row.path) {

        let a = document.createElement('a')
        a.href = row.url
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
        return

      }


      // 思维导图跳转
      if (row.typeId === 52) {
        const { href } = this.$router.resolve({
          name: "graph",
          query: {
            id: row.id,
          },
        });
        window.open(href, "_blank");
        return;
      }
      //文件
      if (row.isFile) {
        if (!row.path) return;
        if (row.typeId == 53) {
          this.imgPreview = true;
          this.showImageName = row.name
          this.showImage = row.path;
          return;
        } else {

          //因为服务器问题 文档先禁用
          // if (row.fileTypeName == 'pdf') {
          //   return window.open(row.path);
          // }
          return window.open(row.path);
        }
      }

      let id = row.id;
      this.$router.replace({
        name: "case",
        query: { id },
      });
      this.cur = row.id;
      this.init();
    },
    // 下载预览图片
    downLoadImage() {
      download(this.showImage, `${this.showImageName}.png`);
    },
    // 点击面包屑
    onBreadcrumb(index) {
      const { breadcrumbList, cur } = this;

      if (index === 0 || breadcrumbList[index].id === cur) {
        return;
      }

      let id = breadcrumbList[index].id;
      this.$router.replace({
        name: "case",
        query: { id },
      });
      this.cur = breadcrumbList[index].id;

      this.init();
    },
    // 下载
    download(row) {

      if (!row.url) return;
      if (row.typeId == 52) {
        download(row.url, `${row.name}.png`);
      } else if (row.typeId == 99) {
        let a = document.createElement('a')
        a.href = row.url
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
        return
      } else {
        download(row.url, `${row.name}.${row.fileTypeName}`);
      }
    },
    // 设置常办
    often(row) {
      this.SETMSGDIALOG({
        title: "系统提示",
        message: `确定要设置该${row.isFile ? "文件" : "文件夹"}为常办吗？`,
        width: "439px",
        type: "error",
        commit: () => {
          const api = row.isFile ? "setOftenByFile" : "setOftenByFloder";

          this.$api.cases[api]({
            id: row.id,
          }).then(() => {
            this.getList();
            this.SETMSGDIALOG(null);
          });
        },
      });
    },
    // 取消常办
    unoften(row) {
      this.SETMSGDIALOG({
        title: "系统提示",
        message: `确定要取消该${row.isFile ? "文件" : "文件夹"}常办吗？`,
        width: "439px",
        type: "error",
        commit: () => {
          const api = row.isFile ? "unoftenByFile" : "unoftenByFloder";

          this.$api.cases[api]({
            id: row.id,
          }).then(() => {
            this.getList();
            this.SETMSGDIALOG(null);
          });
        },
      });
    },
    // 权限设置
    permiss(row) {
      const { id, isFile } = row;
      this.$refs.permissDialog.show({ id, isFile });
    },
    // 权限回调
    peimissCommit(params) {
      const api = params.isFile ? "setUsersDenyByFile" : "setUsersDenyByFloder";

      this.$api.cases[api]({
        id: params.id,
        userId: params.userId,
      }).then(() => {
        this.$refs.permissDialog.visible = false;
      });
    },
    // 获取罪名列表
    getGlobalList() {
      this.$api.system
        .getGlobalList({
          type: "crimeItems",
        })
        .then((res) => {
          this.chargeList = res.crimeItems || [];
        });
    },
    // 创建辩护罪名目录
    createDefendCommit(params) {
      this.$api.cases
        .createDefendFolder({
          parentId: params.parentId,
          human: params.human,
          name: params.name.join(" "),
        })
        .then(() => {
          this.init();
          this.$refs.createDefend.visible = false;
        });
    },
    // 重命名辩护罪名目录
    renameDefendCommit(params) {
      this.$api.cases
        .renameDefendFolder({
          id: params.id,
          human: params.human,
          name: params.name.join(" "),
        })
        .then(() => {
          this.getList();
          this.$refs.renameDefend.visible = false;
        });
    },
    // 创建报案罪名目录
    createReportCommit(params) {
      this.$api.cases
        .createReportFolder({
          parentId: params.parentId,
          human: params.human,
          name: params.name.join(" "),
        })
        .then(() => {
          this.init();
          this.$refs.createReport.visible = false;
        });
    },
    // 重命名报案罪名目录
    renameReportCommit(params) {
      this.$api.cases
        .renameReportFolder({
          id: params.id,
          human: params.human,
          name: params.name.join(" "),
        })
        .then(() => {
          this.getList();
          this.$refs.renameReport.visible = false;
        });
    },
    // 创建报案罪名目录
    createLitigationCommit(params) {
      this.$api.cases.createLitigationFolder(params).then(() => {
        this.init();
        this.$refs.createLitigation.visible = false;
      });
    },
    // 重命名报案罪名目录
    renameLitigationCommit(params) {
      this.$api.cases.renameLitigationFolder(params).then(() => {
        this.getList();
        this.$refs.renameLitigation.visible = false;
      });
    },
    // 获取辩护案件信息
    getDefendInfo() {
      const {
        cur,
        basicsParams,
        humanParams,
        examineParams,
        prosecutionParams,
        trialFirstParams,
        trialSecondParams,
        trialFirstRepeatParams,
        trialSecondRepeatParams,
        appealParams,
      } = this;

      this.$api.cases.getDefendInfo({ casesId: cur }).then((res) => {
        Object.keys(basicsParams).forEach((v) => {
          this.basicsParams[v] = res.basics[v] || "";
        });
        Object.keys(humanParams).forEach((v) => {
          if (v === "crimeName") {
            this.humanParams[v] = res.human[v] ? res.human[v].split(" ") : [];
          } else {
            this.humanParams[v] = res.human[v] || "";
          }
        });
        Object.keys(examineParams).forEach((v) => {
          this.examineParams[v] = res.examine[v] || "";
        });
        Object.keys(prosecutionParams).forEach((v) => {
          this.prosecutionParams[v] = res.prosecution[v] || "";
        });
        Object.keys(trialFirstParams).forEach((v) => {
          this.trialFirstParams[v] = res.trialFirst[v] || "";
        });
        Object.keys(trialSecondParams).forEach((v) => {
          this.trialSecondParams[v] = res.trialSecond[v] || "";
        });
        Object.keys(trialFirstRepeatParams).forEach((v) => {
          this.trialFirstRepeatParams[v] = res.trialFirstRepeat[v] || "";
        });
        Object.keys(trialSecondRepeatParams).forEach((v) => {
          this.trialSecondRepeatParams[v] = res.trialSecondRepeat[v] || "";
        });
        Object.keys(appealParams).forEach((v) => {
          this.appealParams[v] = res.appeal[v] || "";
        });
      });
    },
    // 保存辩护案件信息
    saveDefendInfo(key) {
      const apiList = {
        basicsParams: "saveBasics",
        humanParams: "saveHuman",
        examineParams: "saveExamine",
        prosecutionParams: "saveProsecution",
        trialFirstParams: "saveFirst",
        trialSecondParams: "saveSecond",
        trialFirstRepeatParams: "saveFirstRepeat",
        trialSecondRepeatParams: "saveSecondRepeat",
        appealParams: "saveAppeal",
      };
      const { breadcrumb } = this;
      const params =
        key === "humanParams"
          ? {
            ...this[key],
            crimeName:
              this[key].crimeName && this[key].crimeName.length
                ? this[key].crimeName.join(" ")
                : [],
          }
          : { ...this[key] };

      this.$api.cases[apiList[key]]({
        ...params,
        casesId: breadcrumb.id,
      }).then(() => {
        this.success({
          message: "保存成功",
        });
        if (key === "basicsParams") {
          this.getList();
        }
        this.curEditKey = "";
      });
    },
    // 添加工作日志
    createWorkLogs() {
      this.$refs.createWorkLogs.show();
    },
    // 添加工作日志回调
    createWorkLogsCommit(params) {
      const { breadcrumb } = this;

      this.$api.cases
        .createWorkLogs({
          ...params,
          casesId: breadcrumb.id,
        })
        .then(() => {
          this.success({
            message: "添加成功",
          });
          this.$refs.logs.init();
          this.$refs.createWorkLogs.visible = false;
        });
    },
    // 编辑工作日志
    editWorkLogs(row) {
      this.$refs.editWorkLogs.show(row);
    },
    // 添加工作日志回调
    editWorkLogsCommit(params) {
      this.$api.cases
        .editWorkLogs({
          ...params,
        })
        .then(() => {
          this.success({
            message: "编辑成功",
          });
          this.$refs.logs.getList();
          this.$refs.editWorkLogs.visible = false;
        });
    },
    // 上传文件
    uploadFile() {
      this.$refs.uploadFile.visible = true;
    },
    // 直接上传文件
    uploadFileDirct() {
      this.$refs.fileInput.click();
    },
    // 文件选择回调
    fileInput(event) {
      const {
        target: { files },
      } = event;
      this.afterSelectFile(files.length);
      this.$refs.uploadFile.upload(files);
      this.$refs.fileInput.value = "";
    },
    afterSelectFile(num) {
      this.$refs.uploadFile.visible = false;
      this.SETLOADING({
        title: "正在上传…",
        message: `文件正在上传中，请稍等 <p style="text-align:center">上传进度：0/${num}</p>`,
      });
    },
    // 上传文件回调
    uploadFileChange(params) {
      const { breadcrumb } = this;
      this.$api.cases
        .uoloadFile({
          ...params,
          parentId: breadcrumb.id,
        })
        .then(() => {
          if (params.uploadDone == params.filesNum) {
            this.init();
            this.SETLOADING(null);
          } else {
            this.SETLOADING({
              title: "正在上传…",
              message: `文件正在上传中，请稍等 <p style="text-align:center">上传进度：${params.uploadDone}/${params.filesNum}</p>`,
            });
          }
        })
        .catch(() => {
          this.$message.error("文件上传失败");
          this.init();
          this.SETLOADING(null);
        });
    },

    uploadFail(params) {
      if (params && params.uploadDone == params.filesNum) {
        this.error({
          message: "文件上传失败",
        });
        this.init();
      }
      this.SETLOADING(null);
    },

    // 创建空白文档
    createBlankFile(typeId) {
      const { breadcrumb } = this;

      this.$refs.uploadFile.visible = false;
      this.SETLOADING({
        title: "正在创建…",
        message: "空白文档正在创建中，请稍等",
      });

      this.$api.cases
        .createBlankFile({
          typeId,
          parentId: breadcrumb.id,
        })
        .then(() => {
          this.init();
          this.SETLOADING(null);
        })
        .catch(() => {
          this.SETLOADING(null);
        });
    },
    // 创建模板文档
    createTemplateFile(typeId) {
      const { breadcrumb } = this;

      this.$refs.uploadFile.visible = false;
      this.SETLOADING({
        title: "正在创建…",
        message: "模板文档正在创建中，请稍等",
      });

      this.$api.cases
        .createTemplateFile({
          typeId,
          parentId: breadcrumb.id,
        })
        .then(() => {
          this.init();
          this.SETLOADING(null);
        })
        .catch(() => {
          this.SETLOADING(null);
        });
    },
    // 新建思维导图
    createXml() {
      const { href } = this.$router.resolve({
        name: "graph",
        query: {
          parentId: this.breadcrumb.id,
        },
      });
      window.open(href, "_blank");
    },
    // 点击图片转pdf
    img2pdf() {
      this.$refs.createPdf.visible = true;
    },
    // 图片转pdf回调
    img2pdfCommit(files) {
      this.$refs.createPdf.visible = false;
      this.SETLOADING({
        title: "正在生成…",
        // message: 'PDF正在生成中，请稍等'
        message: "图片正在上传，请稍等",
      });

      const { breadcrumb } = this;
      const params = new FormData();
      params.append("parentId", breadcrumb.id);

      try {
        let syncImages = [];
        let res, accessid, host, policy, signature, path, name;
        const fileLen = files.length;
        let i = 0;
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = today.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        files.forEach(async (file) => {
          file.newname =
            "temp/" +
            year +
            "/" +
            month +
            day +
            "/" +
            crypto
              .createHash("md5")
              .update(file.name + Math.random())
              .digest("hex") +
            file.name.substr(file.name.lastIndexOf("."));
          params.append("image[" + i + "]", file.newname);
          i++;
        });
        files.forEach(async (file) => {
          res = await this.$api.system.getGlobalList({ type: "ossTempImageSign" });
          accessid = res.ossTempImageSign.accessid;
          host = res.ossTempImageSign.host;
          policy = res.ossTempImageSign.policy;
          signature = res.ossTempImageSign.signature;
          path = res.ossTempImageSign.path;

          const ossPath = file.newname;
          let formData = new FormData();
          formData.append("key", ossPath);
          formData.append("policy", policy);
          formData.append("OSSAccessKeyId", accessid);
          formData.append("signature", signature);
          formData.append("file", file);
          formData.append("success_action_status", 200);

          await this.$api.system.upload(host, formData);
          syncImages.push(ossPath);

          if (syncImages.length === fileLen) {
            this.$api.cases.image2pdf(params).then(() => {
              this.$message({
                message: "图片转换中，请稍后查看！",
                type: "success",
              });
              this.init();
              this.SETLOADING(null);
            });
          }
        });
      } catch (error) {
        console.info(error);
      }
    },
    // 点击跳转阅卷系统
    onMarking() {
      // let link = this.$router.resolve({
      //   name: "marking",
      //   query: {
      //     id: this.breadcrumbList[this.breadcrumbList.length - 1].id,
      //   },
      // });
      // window.open(link.href, "_blank");
      this.$router.push({
        name: 'marking',
        query: {
          id: this.breadcrumbList[this.breadcrumbList.length - 1].id
        }
      })
    },
  },
  created() {
    const { id = 0 } = this.$route.query;
    this.cur = Number(id) || 0;

    this.init();
    this.getGlobalList();
    window.addEventListener("message", (event) => {
      // 我们能信任信息来源吗？
      if (event.origin !== window.location.origin) {
        return;
      }

      const { breadcrumb } = this;

      if (event.data && event.data.type === "refresh" && breadcrumb.typeId === 73) {
        this.getList();
      }
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.collapse-right {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 16px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #60707d;
  line-height: 20px;
}

.less-mt {
  //缩小底部边距
  margin-bottom: 10px;
}

.table-td-input {
  height: 28px;

  ::v-deep input {
    height: 28px;
  }
}

.case-container {
  height: 100%;

  .case-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .breadcrumb-wp {
    width: 100%;
    // height: 17px;
    padding: 0 15px;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    margin-bottom: 16px;

    ::v-deep .el-scrollbar__view {
      display: inline-block;
      white-space: nowrap;
      /* height: 100%; */
    }

    .breadcrumb-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 100%;
      font-size: 12px;
      font-weight: 400;
      color: #9aaab7;
      user-select: none;

      &:not(:last-child) {
        margin-right: 30px;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          background: url("../../assets/image/global/icon_next.png") no-repeat;
          background-size: 100%;
          right: -24px;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: default;
        }
      }

      &:last-child {
        font-weight: 500;
        color: $primary;
      }
    }
  }

  .firstChildYear {
    padding: 0 16px 19px 16px;
    height: 58px;
    max-width: calc(100% - 150px);
    white-space: nowrap;
    overflow-y: hidden;

    .item {
      display: inline-block;
      // width: 78px;
      // height: 32px;
      padding: 0 10px;
      line-height: 30px;
      color: #006aff;
      font-size: 12px;
      background: rgba(0, 106, 255, 0.05);
      border: 1px solid #d1e4ff;
      text-align: center;
      border-radius: 16px;
      position: relative;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &.cur,
      &:hover {
        background: #006aff;
        border-color: #006aff;
        color: #fff;

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid #006aff;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }
    }
  }

  .firstParentChild {
    padding: 16px;

    .item {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 40px;
      }

      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 22px;
      }

      .line {
        margin-top: 11px;
        width: 32px;
        height: 4px;
        border-radius: 2px;
        background: transparent;
      }

      &.cur,
      &:hover {
        .name {
          color: #333333;
        }

        .line {
          background: #f4a93d;
        }
      }
    }
  }

  .firstParent {
    .item {
      width: 190px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background-image: url("../../assets/image/case/aside_item_bg.png");
      background-size: 100% 100%;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #60707d;
      cursor: pointer;

      &.cur,
      &:hover {
        color: #006aff;
      }
    }
  }

  .case-main {
    position: relative;
    width: 100%;
    height: calc(100% - 33px);
    background-color: #ffffff;
    border-radius: 16px;

    .case-main-main {
      height: 100%;
    }

    &.radius {
      border-radius: 0 16px 16px 16px;
      height: calc(100% - 81px);
    }

    .case-aside {
      position: absolute;
      width: 234px;
      height: 100%;
      transition: width 0.2s;
      left: 0;
      top: 0;
      z-index: 2;
      padding: 16px;

      .el-scrollbar {
        height: calc(100% - 34px);
      }

      &::-webkit-scrollbar {
        width: 0;
      }

      &.w-0 {
        width: 0;

        .case-show {
          height: 0;
          width: 0;
        }
      }

      .case-show {
        margin-bottom: 16px;

        .left {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #60707d;
          line-height: 20px;
        }

        .case-show-left {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #60707d;

          img {
            width: 18px;
            height: 18px;
          }
        }

        // position: absolute;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // width: 10px;
        // height: 60px;
        // border-radius: 0 2px 2px 0;
        // background: #fff;
        // color: #A5AEC1;
        // right: -10px;
        // box-shadow: 2px 0px 4px 0px rgba(139, 156, 191, 0.1);
        // user-select: none;
        // cursor: pointer;
        // overflow: hidden;
        // top: 0;
        // bottom: 0;
        // margin: auto;
      }

      .case-show-right {
        position: absolute;
        top: 50%;
        left: 7px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #60707d;
        width: 18px;
        text-align: center;
        transform: translateY(-50%);

        img {
          width: 18px;
          height: 18px;
        }
      }

      .aside-add-item {
        width: 202px;
        height: 40px;
        padding: 10px 20px;
        border-radius: 4px;
        border: 1px dashed #a5aec1;
        margin: auto;
        margin-bottom: 15px;
        user-select: none;
        cursor: pointer;

        >p {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #a5aec1;
          word-break: break-all;
          line-height: 20px;
        }
      }

      .aside-item {
        width: 202px;
        background: #ffffff;
        box-shadow: 0px 5px 15px 0px #eff3f7;
        border-radius: 8px;
        padding: 15px;
        margin: auto;
        margin-bottom: 15px;
        user-select: none;
        cursor: pointer;

        &.cur {
          background: #40b9a4;
          box-shadow: 0px 5px 15px 0px rgba(64, 185, 164, 0.19);

          >p {
            color: #fff;
          }
        }

        >p {
          word-break: break-all;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #60707d;
          line-height: 20px;
          text-align: justify;
        }
      }

      .aside-item-second {
        display: flex;
        align-items: center;
        width: 202px;
        background: #ffffff;
        box-shadow: 0px 5px 15px 0px #eff3f7;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 15px;
        user-select: none;
        cursor: pointer;
        margin-bottom: 15px;

        &.cur {
          &.ing {
            background: #40b9a4;
            box-shadow: 0px 5px 15px 0px rgba(64, 185, 164, 0.19);

            .item-tip {
              background: #fff !important;
              color: #40b9a4;
            }
          }

          &.end {
            background: #40b9a4;
            box-shadow: 0px 5px 15px 0px rgba(64, 185, 164, 0.19);
          }

          .item-tip {
            background: #fff !important;
          }

          >p {
            color: #fff;
          }
        }

        &.ing {
          .item-tip {
            background: rgba($color: #c3cfd9, $alpha: 0.1);
            color: #9aaab7;
          }
        }

        &.end {
          .item-tip {
            background: rgba($color: $primary, $alpha: 0.1);
            color: #40b9a4;
          }
        }

        .item-tip {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 44px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 4px;
          text-indent: 4px;
          writing-mode: vertical-lr;
        }

        >p {
          flex: 1;
          margin-left: 10px;
          word-break: break-all;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #383d41;
          line-height: 20px;
        }
      }
    }

    .case-content {
      position: absolute;
      display: flex;
      height: 100%;
      transition: width 0.2s;
      right: 0;
      top: 0;
      z-index: 1;

      .left {
        width: 100%;
        height: 100%;

        &.narrow {
          width: calc(100% - 281px);
        }

        .content-tabel {
          padding: 16px 10px;

          .table-hd {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            position: relative;

            .hd-title {
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #60707d;
              line-height: 20px;
            }

            .btn-box {
              display: flex;
              align-items: center;

              >div:not(:last-child) {
                margin-right: 10px;
              }
            }
          }

          .table-btn-box {
            >div:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }

      .t-tabel {
        border-radius: 8px;
        border-color: transparent !important;

        &.el-table--border {
          border-color: transparent !important;
        }

        .el-table__header {
          background-color: #f4f7fa;
          border-radius: 8px 8px 0 0;
        }


      }

      .right {
        width: 281px;
        height: 100%;
        border-left: 1px solid $line;

        .right-wp {
          border: none;
          padding: 0 16px 0 17px;

          ::v-deep .el-collapse-item {
            &:not(:last-child) {
              border-bottom: 1px solid #f1f3f6;
            }

            font-weight: 700 !important;

            &.is-active {
              width: 100%;
              width: calc(100% + 48px);
              margin-left: -24px;
              padding: 0 24px;
              background: #f9f9f9;
              position: relative;
              font-weight: 700;

              .el-collapse-item__wrap {
                width: 100%;
                background: rgba(247, 249, 251, 0.4);
                padding: 14px;
                font-weight: 700;
              }

              .top-button {
                position: absolute;
                top: 13px;
                right: 36px;
                font-weight: 700;
              }
            }

            .el-form-item {
              &.el-form-item--medium {
                margin-bottom: 10px;
              }
            }

            .el-collapse-item__header {
              height: 50px;
              line-height: 50px;
              font-size: 16px;
              color: #383d41;
              border: none;
              background: none;
              font-weight: 700;

              &.is-active {
                color: #383d41;
              }

              .el-icon-arrow-right {
                color: #006aff;
                font-size: 12px;
                transform: scale(0.5);
                width: 32px;
                height: 32px;
                border: 1px solid #c3cfd9;
                border-radius: 50%;
                text-align: center;
                line-height: 30px;
              }
            }

            .el-collapse-item__wrap {
              border: none;
              background: none;

              .el-form-item__label {
                padding: 0 10px 0 0;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
                text-align: left;
              }

              .el-collapse-item__content {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
                line-height: 18px;
                padding-bottom: 0;
              }

              .el-radio {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.year-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .year-del {
    margin-left: 20px;
    background: #F4A93D;
  }
}
</style>
