<template>
  <div class="home-container">
    <nav-layout-home></nav-layout-home>
    <div class="main-content">
      <header-layout-home></header-layout-home>
      <div class="main-content-content">
        <div class="home-page">
          <div class="left-column">
            <div class="ofter-case">
              <div class="coop-head flex jc_b">
                <p class="coop-title flex ai_c">
                  <i class="coop-title-icon aj"></i>
                  案件管理
                </p>
              </div>
              <div class="tabs-box">
                <div v-if="index < 3" v-for="(item, index) in tabslist" :key="item.key" class="tab-unit" :class="item.key"
                  @click="go(item.name, { id: statInfo[item.key].id }, item.key)">
                  <p class="value">
                    {{ statInfo[item.key] ? statInfo[item.key].count : 0 }}
                  </p>
                  <p>{{ item.label }}</p>
                </div>
              </div>
              <div class="tabs-box2">
                <div v-if="index >= 3" v-for="(item, index) in tabslist" :key="item.key" class="tab-unit"
                  :class="item.key" @click="go(item.name, { id: statInfo[item.key].id })">
                  <div :class="item.key"></div>
                  <div>
                    <p class="label">{{ item.label }}</p>
                    <p class="value">
                      文件数 {{ statInfo[item.key] ? statInfo[item.key].count : 0 }}
                    </p>
                  </div>
                  <div class="icon-more">
                    <img src="~@/assets/image/home/icon_more_gray.png" alt="" />
                  </div>
                </div>
                <div class="tab-unit template" @click="go('case', { id: statInfo.clerical.id })">
                  <div class="template"></div>
                  <div class="">
                    <p class="label">模板文件</p>
                    <p class="value">文档模板</p>
                  </div>
                  <div class="icon-more">
                    <img src="~@/assets/image/home/icon_more_gray.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tabs-box3">
                <div class="conter">
                  <div class="left">
                    <div class="image">
                      <img src="~@/assets/image/home/message-icon.png" alt="">
                    </div>
                    <div class="cont">
                      <el-carousel height="17px" trigger="click" direction="vertical" :autoplay="true" :interval="3000" :initial-index="0" >
                        <el-carousel-item class="carousel-item" v-for="(item,index) in carouselList" :key="index" @click.native="clickCarousel(item)">
                          <span>{{ item.title }}</span>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                  <div class="right">
                    <div class="icon-more">
                    <img src="~@/assets/image/home/icon_more_gray.png" alt="" />
                  </div>
                  </div>
                </div>
              </div> -->
            <div class="ofter-case">
              <div class="coop-head flex jc_b">
                <!-- <p class="coop-title flex ai_c pointer" @click="go('case')"> -->
                <p class="coop-title flex ai_c">
                  <i class="coop-title-icon cb"></i>
                  常办案件
                  <!-- <img src="~@/assets/image/home/icon_more_gray.png" alt=""> -->
                </p>
              </div>
              <div class="list-box">
                <div v-for="item in oftenList" :key="'oftenList_' + item.id" class="list-item" v-if="oftenList.length"
                  @click="go('case', { id: item.id })">
                  <p class="date">{{ item.createDate }}</p>
                  <p class="name">{{ item.name }}</p>
                </div>
                <div class="nothing-box" v-show="!oftenList.length">
                  <i class="not-icon" />
                  <p class="not-tit">暂无内容</p>
                </div>
                <div class="pagination">
                  <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    layout="prev, pager, next" :total="totalCount">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>

          <div class="right-column">
            <div class="notice">
              <div class="image">
                <img src="~@/assets/image/home/message-icon.png" alt="">
              </div>
              <div class="notice-cont">
                <el-carousel height="34px" trigger="click" indicator-position="outside" direction="horizontal" :autoplay="true" :interval="5000"
                  :initial-index="0">
                  <el-carousel-item class="carousel-item" v-for="(item, index) in carouselList" :key="index"
                    @click.native="clickCarousel(item)">
                    <span>{{ item.title }}</span>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="calendar">
              <div class="flex jc_b coop-head">
                <p class="coop-title flex ai_c">
                  <i class="coop-title-icon rq"></i>
                  {{ monthText }}
                </p>
                <t-btn @click="go('calendar')" type="primary" fs="12px" w="78px" h="32px" radius="16px" plain>
                  <img class="icon_add" src="@/assets/image/home/icon_add.png" />
                  日程
                </t-btn>
              </div>
              <p class="month-list">
                <span>Mon</span>
                <span>Tue</span>
                <span>Wed</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
                <span>Sun</span>
              </p>
              <div class="date-list">
                <div v-for="(date, dindex) in dates" :key="date + dindex">
                  <span :class="getDateClass(date)">
                    {{ date.date.date }}
                  </span>
                </div>
              </div>
              <div class="schedule-list">
                <div v-if="today.length" v-for="item in today" :key="'schedule_' + item.id" class="schedule-unit">
                  <p class="time-section">{{ item.time }} - {{ item.endTime }}</p>
                  <div class="unit-content">
                    <p>{{ item.content }}</p>

                    <div v-if="item.users && item.users.length" class="flex">
                      <span v-for="v in item.users" :key="v.id" class="username">
                        @{{ v }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="nothing-box not-schedule" v-show="!today.length">
                  <i class="not-icon" />
                  <p class="not-tit">今日暂无日程安排</p>
                </div>
              </div>
            </div>
            <div class="case">
              <div class="flex jc_b coop-head">
                <p class="coop-title flex ai_c pointer" @click="go('forum')">
                  <i class="coop-title-icon yw"></i>
                  业务交流
                  <img src="~@/assets/image/home/icon_more_gray.png" alt="" />
                </p>
              </div>

              <div class="business-list">
                <p v-for="v in forumList" :key="'forumList_' + v.id" @click="go('forumDetail', {}, { id: v.id })"
                  class="list-item">
                  {{ v.title }}
                </p>
              </div>
              <div class="flex jc_b coop-head">
                <p class="coop-title flex ai_c pointer" @click="go('cooperation')">
                  <i class="coop-title-icon ajxz"></i>
                  案件协作
                  <img src="~@/assets/image/home/icon_more_gray.png" alt="" />
                </p>
              </div>

              <div class="coop-list">
                <p v-for="v in coopList" :key="'coopList_' + v.id" @click="go('cooperation', {}, { info: v })"
                  class="list-item">
                  <span>{{ v.area }}-{{ v.type }}</span>
                  <span>{{ v.createAt && v.createAt.substr(0, 10) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-logo"></div>
    </div>

    <message-detail ref="messageDetail"></message-detail>
  </div>
</template>

<script>
import NavLayoutHome from "@/components/layout/components/nav-layout-home.vue";
import HeaderLayoutHome from "@/components/layout/components/header-layout-home.vue";
import messageDetail from "./components/messageDetail.vue";
export default {
  name: "home",
  components: {
    NavLayoutHome,
    HeaderLayoutHome,
    messageDetail
  },
  data() {
    return {
      tabslist: [
        {
          label: "刑事案件",
          icon: "xingshi",
          key: "criminal",
          name: "case",
          tColor: "#35214E",
        },
        {
          label: "民事案件",
          icon: "minshi",
          key: "civil",
          name: "case",
          tColor: "#4E4721",
        },
        {
          label: "非诉案件",
          icon: "feisu",
          key: "litigation",
          name: "case",
          tColor: "#21334E",
        },
        {
          label: "事务文件",
          icon: "shiwu",
          key: "affair",
          name: "affair",
          tColor: "#214E21",
        },
        {
          label: "个人文件",
          icon: "personal",
          key: "private",
          name: "privately",
          tColor: "#29214E",
        },
      ],
      statInfo: {},
      oftenList: [], //常办案件
      monthText: "",
      dates: [],
      today: [],
      forumList: [],
      coopList: [],
      totalCount: 20,
      carouselList: []
    };
  },
  watch: {
    curGroupId(val) {
      val && this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getStat();
      this.getOften();
      this.getTime();
      this.getCalendar();
      this.getForum();
      this.getCoop();
      this.getRollAll()
    },
    getRollAll() {
      this.$api.home.rollAll().then((res) => {

        this.carouselList = res
      });
    },

    clickCarousel(item) {
      console.log(item)
      this.$refs.messageDetail.visible = true
      this.$refs.messageDetail.init(item)
    },
    getStat() {
      this.$api.home.getStat().then((res) => {
        this.statInfo = res;
      });
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.getOften(val);
    },
    getOften(page) {
      this.$api.home
        .getOften({
          page: page || 1,
          perPage: 8,
        })
        .then((res) => {
          this.oftenList = res.items;
          this.totalCount = res.page.totalCount;
        });
    },
    getTime() {
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      this.monthText = year + "." + (String(month).length == 1 ? "0" + month : month);

      let week = time.getDay();
      let dates = [];
      for (let i = week; i > 1; i--) {
        let t = new Date(time.getTime() - (week - i + 1) * 3600 * 24 * 1000);
        let m = t.getMonth() + 1;
        let d = t.getDate();
        dates.push({
          date: d,
          month: m,
          sameMonth: m == month,
        });
      }
      dates = dates.reverse();
      for (let i = week; i <= 7; i++) {
        let t = new Date(time.getTime() + (i - week) * 3600 * 24 * 1000);
        let m = t.getMonth() + 1;
        let d = t.getDate();
        dates.push({
          date: d,
          month: m,
          sameMonth: m == month,
        });
      }

      let today = time.getDate();
      this.$set(
        this,
        "dates",
        dates.map((d) => {
          return {
            date: d,
            active: d.date == today,
          };
        })
      );
    },
    getCalendar() {
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let dayInfo = time.getDate()
     
      console.log(month)
      if(month <10){
        month = '0'+month
      }
      console.log(month)
      if(dayInfo <10){
        dayInfo = '0'+dayInfo
      }
      let params = {
        year,
        month,
        userId: 0,
      };
      
      let day = year + "-" + month + "-" + dayInfo;
    
      this.$api.calendar.getCalendarList(params).then((res) => {
        let today = res.find((item) => {
          return item.date == day;
        });
    
        this.$set(this, "today", today.items);
      });
    },
    getForum() {
      this.$api.forum
        .list({
          page: 1,
          perPage: 5,
        })
        .then((res) => {
          this.forumList = res.items.filter((v, i) => i < 2);
        });
    },
    getCoop() {
      this.$api.coop
        .list({
          page: 1,
          perPage: 5,
        })
        .then((res) => {
          this.coopList = res.items.filter((v, i) => i < 2);
        });
    },
    getDateClass(date) {
      if (date.active) {
        return "active";
      } else {
        if (!date.date.sameMonth) {
          return "diff-month";
        }
      }
    },
    go(name, query = {}, params = {}) {
      // if (!name) return;
      // if (name == "forum") {
      //   this.$store.commit("SETMSG", {
      //     title: "提示",
      //     type: "error",
      //     message: "正在开发中! 请期待哦~",
      //   });
      // } else {

      this.$router.push({
        name,
        query,
        params,
      });
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  min-height: 750px;
  width: 1400px;
  margin: 0 auto;
  height: 100vh;
  position: relative;
  // background: url(~@/assets/image/home/img_bg.png);
  // background-size: 100% 100%;
  position: relative;

  .main-content {
    height: 100%;
    transition: margin-left 0.28s;
    margin-left: 150px;
    position: relative;
    padding: 96px 16px 30px;
    width: calc(100% - 150px);

    .main-content-content {
      width: 100%;
      height: 100%;
      // padding-bottom: 106px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .home-page {
      display: flex;
      width: 100%;
      height: calc(100vh - 16px);
      padding: 0 16px 16px;

      .coop-head {
        padding: 17px 0;
        min-height: 66px;

        .coop-title {
          height: 32px;
          font-size: 20px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: $primary_title;

          .coop-title-icon {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: 100%;
            margin-right: 2px;

            &.aj {
              background-image: url("~@/assets/image/home/icon-coop-01.png");
            }

            &.cb {
              background-image: url("~@/assets/image/home/icon-coop-02.png");
            }

            &.rq {
              background-image: url("~@/assets/image/home/icon-coop-03.png");
            }

            &.yw {
              background-image: url("~@/assets/image/home/icon-coop-04.png");
            }

            &.ajxz {
              background-image: url("~@/assets/image/home/icon-coop-05.png");
            }
          }

          img {
            margin-left: 5px;
          }
        }

        .icon_add {
          width: 12px;
          height: 12px;
          margin-right: 3px;
        }
      }

      .left-column {
        min-width: 766px;
        flex: 11;
        margin-right: 24px;

        .tabs-box2,
        .tabs-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;

          .tab-unit {
            cursor: pointer;
            font-size: 16px;
            background-size: 100% 100%;
          }
        }

        .tabs-box2 {
          .tab-unit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 220px;
            padding: 28px 0 31px;
            color: #333;

            .icon-more {
              margin-left: 14px;
            }

            .label {
              font-weight: 600;
              color: $primary_title;
              line-height: 22px;
              font-size: 16px;
            }

            .value {
              font-weight: 600;
              color: #9aaab7;
              line-height: 17px;
              font-size: 12px;
            }

            .affair,
            .private,
            .template {
              width: 40px;
              height: 40px;
              background-size: 100% 100%;
              margin-right: 8px;
            }

            .affair {
              background-image: url("~@/assets/image/home/icon-affair.png");
            }

            .private {
              background-image: url("~@/assets/image/home/icon-private.png");
            }

            .template {
              background-image: url("~@/assets/image/home/icon-template.png");
            }
          }
        }

        .tabs-box3 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;

          .conter {
            width: 100%;
            height: 40px;
            background: #FFFFFF;
            border-radius: 0px 0px 10px 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 14px 0 14px;
            border-top: 1px solid rgba(247, 249, 251, 1);

            .left {
              width: 95%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .image {
                width: 18px;
                height: 18px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .cont {
                margin-left: 2px;
                width: 100%;
                height: 100%;
                margin-bottom: 3px;

                .carousel-item {
                  cursor: pointer;
                }
              }
            }

          }
        }

        .tabs-box {
          padding-bottom: 28px;
          border-bottom: 1px solid #dfe2e7;
          margin-top: 5px;

          .tab-unit {
            color: #ffffff;
            padding: 16px 22px;
            width: 220px;
            height: 100px;

            .value {
              font-weight: 800;
              line-height: 40px;
              font-size: 28px;
            }

            &:not(:nth-child(3n)) {
              margin-right: 26;
            }

            &.criminal {
              background-image: url("~@/assets/image/home/icon-criminal.png");
            }

            &.civil {
              background-image: url("~@/assets/image/home/icon-civil.png");
            }

            &.litigation {
              background-image: url("~@/assets/image/home/icon-litigation.png");
            }
          }
        }

        .ofter-case {
          background: rgba(255, 255, 255, 0.7);
          border-radius: 16px 16px 0px 0px;
          border: 1px solid #ffffff;
          padding: 0 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.7);

          +.ofter-case {
            margin-top: 24px;
          }

          .list-box {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0 0 24px;
            min-height: 308px;

            .pagination {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
            }

            .list-item {
              width: 354px;
              height: 68px;
              background: rgba(255, 255, 255, 0.9);
              border-radius: 8px;
              margin-bottom: 4px;
              border: 1px solid rgba(255, 255, 255, 0.9);
              width: 354px;
              padding: 13px 30px 13px 17px;
              position: relative;
              cursor: pointer;

              &:not(:nth-child(2n)) {
                margin-right: 8px;
              }

              &:after {
                content: "";
                display: inline;
                width: 12px;
                height: 12px;
                background-size: 100%;
                position: absolute;
                right: 17px;
                top: 50%;
                margin-top: -6px;
              }

              &:hover {
                border-color: #006aff;
                background: #f8fbff;

                &:after {
                  background-image: url("~@/assets/image/home/icon_more_blue.png");
                }
              }

              .date {
                font-weight: 500;
                color: #9aaab7;
                line-height: 20px;
                font-size: 14px;
              }

              .name {
                font-weight: 500;
                color: #60707d;
                line-height: 20px;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .right-column {
        flex: 5;
        min-width: 340px;
        height: 100px;
        vertical-align: top;

        .notice {
          width: 340px;
          height: 86px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 16px;
          border: 1px solid #FFFFFF;
          padding: 25px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .image {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .notice-cont {
            margin-left: 5px;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            width: 258px;
            height: 34px;
            font-weight: 500;
            font-size: 12px;
            color: #383D41;
            line-height: 17px;
            text-align: left;
            font-style: normal;

            .carousel-item {
             
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              cursor: pointer;
              span{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
             
              }
            }
          }
        }

        .calendar {
          margin-top: 16px;
          width: 340px;
          // height: 341px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 16px;
          border: 1px solid #ffffff;
          padding: 0 24px;

          .month-text {
            font-size: 18px;
            color: #9aaab7;
            line-height: 22px;
            margin-bottom: 16px;
          }

          .month-list {
            display: flex;
            align-items: center;

            >span {
              flex: 1;
              font-size: 12px;
              color: #9aaab7;
              text-align: center;
              line-height: 20px;
            }
          }

          .date-list {
            display: flex;
            align-items: center;
            padding-bottom: 26px;
            border-bottom: 1px solid #eff0f3;

            >div {
              flex: 1;
              font-size: 15px;
              color: #21334e;
              text-align: center;
              height: 40px;
              line-height: 40px;
              width: 40px;
              margin-top: 20px;
              font-weight: 600;

              >.active {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                width: 30px;
                background: #056bfd;
                border-radius: 50%;
                color: #fff;
              }

              >.diff-month {
                color: #97a7b9;
              }
            }
          }

          .schedule-list {
            height: 186px;
            padding: 16px 0;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0;
            }

            .schedule-unit {
              margin-bottom: 16px;

              .unit-content {
                margin-top: 14px;
                background: rgba(248, 249, 251, 0.8);
                border-radius: 8px;
                padding: 12px 14px;
                font-weight: 500;
                color: $primary_title;
                font-size: 14px;

                p {
                  line-height: 22px;
                }

                .username {
                  font-weight: 500;
                  color: #006aff;
                  line-height: 22px;
                  font-size: 14px;
                  margin-top: 6px;
                  margin-right: 8px;
                }
              }

              .time-section {
                font-weight: 600;
                color: $primary_title;
                line-height: 22px;
                font-size: 16px;

                &::before {
                  content: " ";
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  border-radius: 8px;
                  background: #f4a93d;
                  transform: translateY(-2px);
                }
              }
            }
          }
        }

        .case {
          margin-top: 22px;
          width: 340px;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 10px 30px 0px rgba(178, 182, 220, 0.3);
          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0.9);
          // backdrop-filter: blur(10px);
          padding: 0 24px;

          .business-list {
            min-height: 84px;
            padding-bottom: 32px;
            border-bottom: 1px solid #e1e4e8;
            margin-bottom: 6px;

            &>p {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #60707d;
              line-height: 20px;
              cursor: pointer;

              &:not(:first-child) {
                margin-top: 14px;
              }
            }
          }

          .coop-list {
            min-height: 84px;
            padding-bottom: 43px;

            &>p {
              display: flex;
              justify-content: space-between;
              cursor: pointer;

              >span:first-child {
                color: #4d5f7b;
              }

              >span:last-child {
                color: #7384a0;
              }

              &:not(:first-child) {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }

  .bottom-logo {
    position: absolute;
    bottom: 48px;
    left: 0;
    width: 100%;
    height: 38px;
    background: url("../../assets/image/home/bg_img_shuiyin@3x.png") no-repeat;
    background-size: 100%;
  }
}
</style>
