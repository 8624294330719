<template>
    <div>
        <el-dialog :visible.sync="visible" :close-on-click-modal="false" :append-to-body="true" width="664px" :title="title"
           >
            <div class="time">发布时间： <span>{{ info.updated_at }}</span></div>

            <div class="detail">{{ info.content }}</div>
        </el-dialog>
    </div>
</template>
<script>
import { mapMutations } from "vuex";

export default {

    data() {
        return {
            visible: false,
            title: "",
            info:{}
        };
    },

    methods: {
     
        init(row) {
            this.$api.home.rollAllDetail({id:row.id}).then((res) => {
                this.title = res.title
                this.info = res
            });
        },

    },

};
</script>
<style lang="scss" scoped>

.time{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    span{
        color:rgba(56, 61, 65, 1);
    }
}

.detail{
    margin-top: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
}

.content-body {
    height: 320px;
}

::v-deep .el-dialog__header .el-dialog__title {
    font-size: 16px !important;
}

.content {
    width: 100%;

    &-item {
        padding: 10px 16px;
        cursor: pointer;

        &:hover {
            background-color: #f5f7fa;

            .name {
                color: #006aff;
            }
        }

        .name {
            flex: 1;
            font-weight: 500;
            color: #60707d;
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
        }

        .checked {
            width: 50px;
            text-align: right;
            color: #006aff;
        }
    }

    &-title {
        height: 44px;
        background: #f4f7fa;
        border-radius: 8px 8px 0px 0px;
        padding: 0 16px;

        .name {
            flex: 1;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #383d41;
        }

        .checked {
            width: 50px;
            text-align: right;
        }
    }

    &-body {
        max-height: 800px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}


</style>
